<template>
  <div>
    <h1>Usuários</h1>

    <v-btn
      color="primary"
      class="ma-2"
      @click="$router.push({ name: 'users.create' })"
    >
      Novo
    </v-btn>

    <v-data-table
      @click:row="handleClick"
      :item-class="() => 'cursor-pointer'"
      :loading="loading"
      :items="users"
      :headers="headers"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="loading"
          color="primary"
          @click="
            (event) => {
              event.stopPropagation();
              handleEditPassword(item.id);
            }
          "
        >
          Trocar Senha
        </v-btn>
        <v-btn
          :disabled="loading"
          color="error"
          class="mx-2"
          @click="
            (event) => {
              event.stopPropagation();
              deleteUser(item.id);
            }
          "
        >
          <v-icon>mdi-delete</v-icon>
          Excluir
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="editingPassword">
      <v-card>
        <v-card-title>Editar Senha</v-card-title>

        <v-card-text>
          <v-form @submit.prevent="updatePassword" ref="formUpdatePassword">
            <v-text-field
              label="Nova Senha"
              :rules="[
                (v) => !!v || 'Preencha este campo',
                (v) =>
                  v.length >= 8 || 'A senha deve ter pelo menos 8 caracters',
              ]"
              v-model="editingUser.password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="!showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <v-text-field
              label="Repetir Senha"
              :rules="[
                (v) => !!v || 'Preencha este campo',
                (v) => v === editingUser.password || 'As senhas não conferem',
              ]"
              v-model="editingUser.passwordConfirmation"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              :append-icon="
                !showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'
              "
              @click:append="
                showPasswordConfirmation = !showPasswordConfirmation
              "
            ></v-text-field>

            <v-btn
              :loading="saving"
              :disabled="saving"
              color="primary"
              class="mx-2"
              type="submit"
            >
              Salvar
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getUsers();
  },
  data() {
    return {
      saving: false,
      editingUser: {
        id: null,
        password: "",
        passwordConfirmation: "",
      },
      showPassword: false,
      showPasswordConfirmation: false,
      editingPassword: false,
      loading: false,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Email", value: "email" },
        { text: "Tipo", value: "role" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    async deleteUser(userId) {
      if (
        confirm(
          "Deseja mesmo remover este usuário? Esta ação não pode ser desfeita"
        )
      ) {
        this.loading = true;
        try {
          await this.$http.delete(`api/users/${userId}`);
          this.$toast.success("Removido");
          this.users = this.users.filter((user) => user.id !== userId);
          this.editingUser = {
            id: null,
            password: "",
            passwordConfirmation: "",
          };
        } catch (error) {
          this.$toast.error("Erro ao remover");
        }
        this.loading = false;
      }
    },
    async updatePassword() {
      const form = this.$refs.formUpdatePassword;

      if (form.validate()) {
        this.saving = true;
        try {
          await this.$http.patch(`api/users/${this.editingUser.id}`, {
            password: this.editingUser.password,
          });
          this.editingPassword = false;
          this.$toast.success("Salvo");
          form.resetValidation();
        } catch (error) {
          this.$toast.error("Erro ao salvar");
        }
        this.saving = false;
      }
    },
    handleEditPassword(userId) {
      this.editingUser.id = userId;
      this.editingPassword = true;
    },
    handleClick(event) {
      this.$router.push({ name: "users.edit", params: { id: event.id } });
    },
    async getUsers() {
      this.loading = true;
      const { data } = await this.$http.get("api/users");
      this.users = data;
      this.loading = false;
    },
  },
};
</script>
