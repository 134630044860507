var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Usuários")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'users.create' })}}},[_vm._v(" Novo ")]),_c('v-data-table',{attrs:{"item-class":function () { return 'cursor-pointer'; },"loading":_vm.loading,"items":_vm.users,"headers":_vm.headers,"disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":function (event) {
            event.stopPropagation();
            _vm.handleEditPassword(item.id);
          }}},[_vm._v(" Trocar Senha ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.loading,"color":"error"},on:{"click":function (event) {
            event.stopPropagation();
            _vm.deleteUser(item.id);
          }}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Excluir ")],1)]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.editingPassword),callback:function ($$v) {_vm.editingPassword=$$v},expression:"editingPassword"}},[_c('v-card',[_c('v-card-title',[_vm._v("Editar Senha")]),_c('v-card-text',[_c('v-form',{ref:"formUpdatePassword",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Nova Senha","rules":[
              function (v) { return !!v || 'Preencha este campo'; },
              function (v) { return v.length >= 8 || 'A senha deve ter pelo menos 8 caracters'; } ],"type":_vm.showPassword ? 'text' : 'password',"append-icon":!_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.editingUser.password),callback:function ($$v) {_vm.$set(_vm.editingUser, "password", $$v)},expression:"editingUser.password"}}),_c('v-text-field',{attrs:{"label":"Repetir Senha","rules":[
              function (v) { return !!v || 'Preencha este campo'; },
              function (v) { return v === _vm.editingUser.password || 'As senhas não conferem'; } ],"type":_vm.showPasswordConfirmation ? 'text' : 'password',"append-icon":!_vm.showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPasswordConfirmation = !_vm.showPasswordConfirmation}},model:{value:(_vm.editingUser.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.editingUser, "passwordConfirmation", $$v)},expression:"editingUser.passwordConfirmation"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"loading":_vm.saving,"disabled":_vm.saving,"color":"primary","type":"submit"}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }